<template>
  <div>
    <el-table
        id="mytable"
        :data="tableData"
        style="width: 100%"
    >
      <el-table-column prop="name" label="姓名" width="180"> </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="mini" @click="addAppoint(scope.row)">预约</el-button>
        </template>
      </el-table-column>
    </el-table>
    <a-modal title="预约" :visible.sync="isAppoint" width="70%" @cancel="closeAppoint">
      <div slot="footer">
        <a-button @click="closeAppoint">取消</a-button>
        <a-button type="primary" @click="saveAppoint" style="margin-left:20px;">确定</a-button>
      </div>
      <div style="margin:20px;height:100%;">
        <div style="display:flex;justify-content:space-between;">
            <span v-for="(item,index) in week" :key="index" :class="{'top_style':item.is_active===0,'top_active':item.is_active===1}" @click="changWeek(item,index)">
              <div style="height:25px;line-height:20px;">{{item.month}}-{{item.date}}</div>
              <div style="height:25px;line-height:20px;">{{item.day}}</div>
            </span>
        </div>
        <div style="display:flex;margin:20px 50px;font-size:18px;justify-content:space-between;">
          <div style="display:flex;"><div style="background-color:#C8C9CC;width:40px;height:20px;margin-right:10px;"></div><div>不可预约</div></div>
          <div style="display:flex;"><div style="background-color:#3EA7F1;width:40px;height:20px;margin-right:10px;"></div><div>当前预约</div></div>
        </div>
        <div style="display:flex;margin:20px 50px;font-size:18px;justify-content:space-between;">
          <a-button v-for="(item,index) in timeArr" :key="index" @click="changTime(item,index)" :type="item.status===1?'primary':''" :disabled="item.status===2">{{item.time}}</a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import {getPointParamsList} from "A/monitoring";

export default {
  data() {
    return {
      tableData: [
        {
          name: "小王",
        },
      ],
      isAppoint:false,
      appointForm:{},
      week:[],//预约周
      timeArr:[{time: "上午（6:00-12:00）", status: 0},
        {time: "下午（12:00-18:00）", status: 0},
        {time: "晚上（18:00-6:00）", status: 0},],
      dateNow:'',//预约日期
    };
  },
  created() {
  },
  methods: {
    closeAppoint(){
      this.isAppoint=false
      for(let i=0;i<this.week.length;i++){
        this.week[i].is_active=0;
      }
      this.week[0].is_active=1;
      for(let i=0;i<this.timeArr.length;i++){
        if(this.timeArr[i].status===3){
          this.timeArr[i].status=0;
        }
      }
    },
    saveAppoint(){
      if(this.selectArr.time){
        // 保存数据，成功后，关闭弹窗，并且再去调用一次获取列表的接口
        this.formData.service_time=this.currentDay+this.selectArr.time
        this.isAppoint=false;
      }else{
        this.$message.error("请选择预约时间")
      }
    },
    getPointParams(day_param){
      getPointParamsList({monitorpointnum:this.formData.monitorpointnum}).then(res=>{
        if(res&&res.returncode==='0'&&res.item.length){
          if(day_param==="mon"){
            this.timeArr[0].status=res.item[0].mon_am_able==="0"?2:0
            this.timeArr[1].status=res.item[0].mon_pm_able==="0"?2:0
            this.timeArr[2].status=res.item[0].mon_nt_able==="0"?2:0
          }else if(day_param==="tue"){
            this.timeArr[0].status=res.item[0].tue_am_able==="0"?2:0
            this.timeArr[1].status=res.item[0].tue_pm_able==="0"?2:0
            this.timeArr[2].status=res.item[0].tue_nt_able==="0"?2:0
          }else if(day_param==="wed"){
            this.timeArr[0].status=res.item[0].wed_am_able==="0"?2:0
            this.timeArr[1].status=res.item[0].wed_pm_able==="0"?2:0
            this.timeArr[2].status=res.item[0].wed_nt_able==="0"?2:0
          }else if(day_param==="thu"){
            this.timeArr[0].status=res.item[0].thu_am_able==="0"?2:0
            this.timeArr[1].status=res.item[0].thu_pm_able==="0"?2:0
            this.timeArr[2].status=res.item[0].thu_nt_able==="0"?2:0
          }else if(day_param==="fri"){
            this.timeArr[0].status=res.item[0].fri_am_able==="0"?2:0
            this.timeArr[1].status=res.item[0].fri_pm_able==="0"?2:0
            this.timeArr[2].status=res.item[0].fri_nt_able==="0"?2:0
          }else if(day_param==="sat"){
            this.timeArr[0].status=res.item[0].sat_am_able==="0"?2:0
            this.timeArr[1].status=res.item[0].sat_pm_able==="0"?2:0
            this.timeArr[2].status=res.item[0].sat_nt_able==="0"?2:0
          }else if(day_param==="sun"){
            this.timeArr[0].status=res.item[0].sun_am_able==="0"?2:0
            this.timeArr[1].status=res.item[0].sun_pm_able==="0"?2:0
            this.timeArr[2].status=res.item[0].sun_nt_able==="0"?2:0
          }
        }
      })
    },
    changTime(val,index){
      if(val.status===0){
        this.timeArr[index].status=1
        this.selectArr=this.timeArr[index]
        if(index===0){
          this.timeArr[1].status=this.timeArr[1].status===2?2:0
          this.timeArr[2].status=this.timeArr[2].status===2?2:0
        }else if(index===1){
          this.timeArr[0].status=this.timeArr[0].status===2?2:0
          this.timeArr[2].status=this.timeArr[2].status===2?2:0
        }else if(index===2){
          this.timeArr[0].status=this.timeArr[0].status===2?2:0
          this.timeArr[1].status=this.timeArr[1].status===2?2:0
        }
      }else if(val.status===1){
        this.timeArr[index].status=0
        this.selectArr={}
      }
    },
    //每次打开预约弹窗时，默认选中当天
    getAppoint(){
      let arr = []
      for (let i = 0; i < 7; i++) {
        arr.push(this.dealTime(i))
      }
      arr[0].is_active=1;
      this.week=arr;
      this.currentDay=this.week[0].day;
      this.dateNow=this.week[0].full;
      this.getPointParams(this.week[0].day_param)
    },
    //点击切换星期状态
    changWeek(val,index){
      for(let i=0;i<this.week.length;i++){
        this.week[i].is_active=0;
      }
      this.week[index].is_active=1;
      this.dateNow=val.full;
      this.currentDay=val.day;
      this.getPointParams(this.week[index].day_param)
      this.getAppointed()
    },
    // 处理未来七天的函数
    dealTime(num){
      let time = new Date() // 获取当前时间日期
      let date = new Date(time.setDate(time.getDate() + num)).getDate() //这里先获取日期，在按需求设置日期，最后获取需要的
      let newDate=(date.toString()).padStart(2,"0");
      let month = time.getMonth() + 1 // 获取月份
      let newMonth=(month.toString()).padStart(2,"0");
      let day = time.getDay() //  获取星期
      let day_param = ""
      let year=time.getFullYear();
      let full=year+'-'+month+'-'+date;
      switch (day) { //  格式化
        case 0:
          day = "星期日"
          day_param = "sun"
          break
        case 1:
          day = "星期一"
          day_param = "mon"
          break
        case 2:
          day = "星期二"
          day_param = "tue"
          break
        case 3:
          day = "星期三"
          day_param = "wed"
          break
        case 4:
          day = "星期四"
          day_param = "thu"
          break
        case 5:
          day = "星期五"
          day_param = "fri"
          break
        case 6:
          day = "星期六"
          day_param = "sat"
          break
      }
      return {
        date: newDate,
        day: day,
        day_param: day_param,
        is_active: 0,
        month: newMonth,
        year: year,
        full: full,
      } // 返回对象
    },
    //时间格式转为年月日
    getYMD(time){
      let date = new Date(time)
      return date.getFullYear() + '-' +
          (date.getMonth() + 1) + '-' +
          date.getDate()
    },
    //预约
    addAppoint(){
      this.isAppoint=true;
      this.getAppoint();
      this.getAppointed();
    },
    //获取时间数组
    getAppointed(){
      //假设当前时间是10:30，则10:30之前的时间点都是过期的，即状态是2，按钮置灰；
      //点击过的视为预约，即状态是1，按钮变蓝
      //默认数据
      this.timeArr=[
        {time: "上午（6:00-12:00）", status: 0},
        {time: "下午（12:00-18:00）", status: 0},
        {time: "晚上（18:00-6:00）", status: 0},
      ];
    },
  },
};
</script>
<style scoped>
.top_style,.top_active{
  border:1px solid #AAA;
  padding:3px 20px;
  text-align:center;
}
.top_active{
  border-color:#02A7F0;
  color: #02A7F0;
}
</style>